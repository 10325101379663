import '../../styles/Projects.scss';
import { tracks } from '../../data/Data';

function Projects() {
  return (
    <div className='projects'>
      <div className='head fade'>
        <h2 className='cursive'>Apperances</h2>
      </div>
      <div className='lists'>
        {tracks.map((item) => (
          <ul key={item.id}>
            <li className='titles slide'>
              <a href={item.url} target='_blank' rel='noreferrer'>
                <span className='cursive'>{item.title}</span>
                <img src={item.art} alt={item.title} />
              </a>{' '}
            </li>
            <li className='artists fade'>
              <code>with</code>{' '}
              {item.with.map((item) => (
                <a
                  key={item.toString()}
                  href={item[1]}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  <span className='pixel'> {item[0] + ' '}</span>
                </a>
              ))}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
}

export default Projects;
