import '../../styles/Home.scss';
import { Info } from '../../data/Data';

function Home() {
  return (
    <div className='home'>
      <div className='logo fade'>
        <img src={Info.logo} alt='' />
      </div>
      <div className='bundle'>
        <div className='skull fade'>
          <img src={Info.skull} alt='' />
        </div>
        <div className='playlist slide'>
          <iframe
            title='playlist'
            width='100%'
            height='300'
            scrolling='no'
            frameBorder='none'
            allow='autoplay'
            src={Info.embed}
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Home;
