import Home from './components/Home/Home';
import Links from './components/Links/Links';
import Projects from './components/Projects/Projects';
import './styles/Fury.scss';
import { Info } from './data/Data';
import { useState } from 'react';
import useAnim from './hooks/useAnim';
function Fury() {
  const [dark, setDark] = useState(false);
  useAnim('fade');
  useAnim('slide');
  return (
    <div className={'app ' + (dark ? 'dark' : '')}>
      <div className='bg fade'>
        <img src={dark ? Info.darkbg : Info.animbg} alt='' />
      </div>
      <div className='pepper fade'>
        <img src={Info.pepper} alt='' onClick={() => setDark(!dark)} />
      </div>
      <Home />
      <Projects />
      <Links />
    </div>
  );
}

export default Fury;
