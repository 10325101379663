export const Info = {
  bg: 'images/bg.png',
  animbg: 'images/animbg.gif',
  chilli: 'images/chilli.png',
  darkbg: 'images/darkbg.gif',
  pepper: 'images/pepper.png',
  brand: 'images/brand.png',
  logo: 'images/logo.png',
  skull: 'images/fire-skull.gif',
  embed:
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1307227321&color=%23ff0000&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
};
export const tracks = [
  {
    id: 0,
    title: 'Broken Pieces',
    art: 'images/brokenpieces.png',
    url: 'https://soundcloud.com/double-zz-99/brokenpieces?in=broccoli-records-music/sets/fury',
    with: [['DBL Z', 'https://dblz.art/']],
  },
  {
    id: 1,
    title: 'NO WAY',
    art: 'images/noway.png',
    url: 'https://soundcloud.com/double-zz-99/no-way?in=broccoli-records-music/sets/fury',
    with: [['DBL Z', 'https://dblz.art/']],
  },
  {
    id: 2,
    title: 'GAMES',
    art: 'images/games.png',
    url: 'https://soundcloud.com/broccoli-records-music/games?in=broccoli-records-music/sets/fury',
    with: [
      ['DBL Z', 'https://dblz.art/'],
      ['Sidoobee', 'https://sidoobee.broccolirecords.com/'],
    ],
  },
  {
    id: 3,
    title: 'BAD',
    art: 'images/bad.png',
    url: 'https://soundcloud.com/broccoli-records-music/bad?in=broccoli-records-music/sets/fury',
    with: [
      ['DBL Z', 'https://dblz.art/'],
      ['M4', 'https://www.instagram.com/muhanna_4/'],
    ],
  },
  {
    id: 4,
    title: "WHAT I'M ON",
    art: 'images/whatimon.png',
    url: 'https://soundcloud.com/broccoli-records-music/what-im-on?in=broccoli-records-music/sets/fury',
    with: [
      ['DBL Z', 'https://dblz.art/'],
      ['M4', 'https://www.instagram.com/muhanna_4/'],
      ['Slum Chemist R.Y', 'https://shinigamilove.broccolirecords.com/'],
    ],
  },
  {
    id: 5,
    title: 'Brain',
    art: 'images/brain.jpeg',
    url: 'https://soundcloud.com/double-zz-99/brain-feat-fury?in=broccoli-records-music/sets/fury',
    with: [['DBL Z', 'https://dblz.art/']],
  },
];
export const Socials = [
  {
    id: 0,
    platform: 'IG',
    url: 'https://www.instagram.com/raging.fury/',
    icon: 'M 8 0 C 5.829 0 5.556 0.01 4.703 0.048 C 3.85 0.088 3.269 0.222 2.76 0.42 a 3.917 3.917 0 0 0 -1.417 0.923 A 3.927 3.927 0 0 0 0.42 2.76 C 0.222 3.268 0.087 3.85 0.048 4.7 C 0.01 5.555 0 5.827 0 8.001 c 0 2.172 0.01 2.444 0.048 3.297 c 0.04 0.852 0.174 1.433 0.372 1.942 c 0.205 0.526 0.478 0.972 0.923 1.417 c 0.444 0.445 0.89 0.719 1.416 0.923 c 0.51 0.198 1.09 0.333 1.942 0.372 C 5.555 15.99 5.827 16 8 16 s 2.444 -0.01 3.298 -0.048 c 0.851 -0.04 1.434 -0.174 1.943 -0.372 a 3.916 3.916 0 0 0 1.416 -0.923 c 0.445 -0.445 0.718 -0.891 0.923 -1.417 c 0.197 -0.509 0.332 -1.09 0.372 -1.942 C 15.99 10.445 16 10.173 16 8 s -0.01 -2.445 -0.048 -3.299 c -0.04 -0.851 -0.175 -1.433 -0.372 -1.941 a 3.926 3.926 0 0 0 -0.923 -1.417 A 3.911 3.911 0 0 0 13.24 0.42 c -0.51 -0.198 -1.092 -0.333 -1.943 -0.372 C 10.443 0.01 10.172 0 7.998 0 h 0.003 Z m -0.717 1.442 h 0.718 c 2.136 0 2.389 0.007 3.232 0.046 c 0.78 0.035 1.204 0.166 1.486 0.275 c 0.373 0.145 0.64 0.319 0.92 0.599 c 0.28 0.28 0.453 0.546 0.598 0.92 c 0.11 0.281 0.24 0.705 0.275 1.485 c 0.039 0.843 0.047 1.096 0.047 3.231 s -0.008 2.389 -0.047 3.232 c -0.035 0.78 -0.166 1.203 -0.275 1.485 a 2.47 2.47 0 0 1 -0.599 0.919 c -0.28 0.28 -0.546 0.453 -0.92 0.598 c -0.28 0.11 -0.704 0.24 -1.485 0.276 c -0.843 0.038 -1.096 0.047 -3.232 0.047 s -2.39 -0.009 -3.233 -0.047 c -0.78 -0.036 -1.203 -0.166 -1.485 -0.276 a 2.478 2.478 0 0 1 -0.92 -0.598 a 2.48 2.48 0 0 1 -0.6 -0.92 c -0.109 -0.281 -0.24 -0.705 -0.275 -1.485 c -0.038 -0.843 -0.046 -1.096 -0.046 -3.233 c 0 -2.136 0.008 -2.388 0.046 -3.231 c 0.036 -0.78 0.166 -1.204 0.276 -1.486 c 0.145 -0.373 0.319 -0.64 0.599 -0.92 c 0.28 -0.28 0.546 -0.453 0.92 -0.598 c 0.282 -0.11 0.705 -0.24 1.485 -0.276 c 0.738 -0.034 1.024 -0.044 2.515 -0.045 v 0.002 Z m 4.988 1.328 a 0.96 0.96 0 1 0 0 1.92 a 0.96 0.96 0 0 0 0 -1.92 Z m -4.27 1.122 a 4.109 4.109 0 1 0 0 8.217 a 4.109 4.109 0 0 0 0 -8.217 Z m 0 1.441 a 2.667 2.667 0 1 1 0 5.334 a 2.667 2.667 0 0 1 0 -5.334 Z',
  },
  {
    id: 1,
    platform: 'Youtube',
    url: 'https://www.youtube.com/channel/UC95ynFzKtfIbACXRvk8jA4g',
    icon: 'M 8.051 1.999 h 0.089 c 0.822 0.003 4.987 0.033 6.11 0.335 a 2.01 2.01 0 0 1 1.415 1.42 c 0.101 0.38 0.172 0.883 0.22 1.402 l 0.01 0.104 l 0.022 0.26 l 0.008 0.104 c 0.065 0.914 0.073 1.77 0.074 1.957 v 0.075 c -0.001 0.194 -0.01 1.108 -0.082 2.06 l -0.008 0.105 l -0.009 0.104 c -0.05 0.572 -0.124 1.14 -0.235 1.558 a 2.007 2.007 0 0 1 -1.415 1.42 c -1.16 0.312 -5.569 0.334 -6.18 0.335 h -0.142 c -0.309 0 -1.587 -0.006 -2.927 -0.052 l -0.17 -0.006 l -0.087 -0.004 l -0.171 -0.007 l -0.171 -0.007 c -1.11 -0.049 -2.167 -0.128 -2.654 -0.26 a 2.007 2.007 0 0 1 -1.415 -1.419 c -0.111 -0.417 -0.185 -0.986 -0.235 -1.558 L 0.09 9.82 l -0.008 -0.104 A 31.4 31.4 0 0 1 0 7.68 v -0.123 c 0.002 -0.215 0.01 -0.958 0.064 -1.778 l 0.007 -0.103 l 0.003 -0.052 l 0.008 -0.104 l 0.022 -0.26 l 0.01 -0.104 c 0.048 -0.519 0.119 -1.023 0.22 -1.402 a 2.007 2.007 0 0 1 1.415 -1.42 c 0.487 -0.13 1.544 -0.21 2.654 -0.26 l 0.17 -0.007 l 0.172 -0.006 l 0.086 -0.003 l 0.171 -0.007 A 99.788 99.788 0 0 1 7.858 2 h 0.193 Z M 6.4 5.209 v 4.818 l 4.157 -2.408 L 6.4 5.209 Z',
  },
  {
    id: 2,
    platform: 'SoundCloud',
    url: 'https://soundcloud.com/master-fury-764389623',
    icon: 'M 20.727 11.6769 C 22.4007 11.6769 23.7573 13.045 23.7573 14.7325 C 23.7573 16.4204 22.4007 17.7885 20.727 17.7885 C 20.673 17.7885 12.3349 17.7844 12.327 17.7836 C 12.1462 17.7654 12.0022 17.6095 12 17.4225 L 12 7.72435 C 12.0022 7.54615 12.0627 7.45458 12.2915 7.3652 C 12.8804 7.13557 13.5464 7 14.2301 7 C 17.0236 7 19.3137 9.1604 19.5551 11.914 C 19.9156 11.7616 20.3119 11.6769 20.727 11.6769 Z M 10.2361 18 L 9.26385 18 C 9.11873 18 9 17.8782 9 17.7293 L 9 7.77066 C 9 7.62153 9.11873 7.5 9.26385 7.5 L 10.2361 7.5 C 10.3813 7.5 10.5 7.62153 10.5 7.77066 L 10.5 17.7293 C 10.5 17.8782 10.3813 18 10.2361 18 Z M 1.23615 16.4972 L 0.263852 16.4972 C 0.118734 16.4972 0 16.376 0 16.228 L 0 13.7664 C 0 13.6183 0.118734 13.4972 0.263852 13.4972 L 1.23615 13.4972 C 1.38127 13.4972 1.5 13.6183 1.5 13.7664 L 1.5 16.228 C 1.5 16.376 1.38127 16.4972 1.23615 16.4972 Z M 7.23615 18 L 6.26385 18 C 6.11873 18 6 17.8844 6 17.7431 L 6 11.2569 C 6 11.1156 6.11873 11 6.26385 11 L 7.23615 11 C 7.38127 11 7.5 11.1156 7.5 11.2569 L 7.5 17.7431 C 7.5 17.8844 7.38127 18 7.23615 18 Z M 4.23615 18 L 3.26385 18 C 3.11873 18 3 17.8872 3 17.7492 L 3 12.7508 C 3 12.6128 3.11873 12.5 3.26385 12.5 L 4.23615 12.5 C 4.38127 12.5 4.5 12.6128 4.5 12.7508 L 4.5 17.7492 C 4.5 17.8872 4.38127 18 4.23615 18 Z',
  },
];
