import '../../styles/Links.scss';
import { Socials } from '../../data/Data';

function Links() {
  return (
    <div className='links cursive fade'>
      <h2>Links</h2>
      <ul>
        {Socials.map((item) => (
          <li key={item.id} className='slide'>
            <a href={item.url} target='_blank' rel='noreferrer'>
              <span>{item.platform}</span>
            </a>
            <svg viewBox='-10 0 35 35'>
              <path d={item.icon} />
            </svg>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Links;
